import { defineStore } from 'pinia'
import { auth } from '@/main'
interface User {
    email: string
    pass: string
}

// Define an interface for the state
interface AuthState {
    user: User
    isAuth: any
    errors: string
    currentUser: any
}

// Define an interface for the getters
interface AuthGetters {
    isAuthenticated(state: AuthState): boolean
}

// Define an interface for the actions
interface AuthActions {
    login(): void
    logout(): void
    setErrors(val: string): void
    [key: string]: any // Allow string index signature for getters or dynamic properties
}

// Define the store
export const useAuthStore = defineStore<'auth', AuthState, AuthActions>('auth', {
    state: (): AuthState => ({
        isAuth: sessionStorage.getItem('login') ? true : false,
        user: { email: '', pass: '' },
        errors: '',
        currentUser: ''
    }),

    // Adding a getters section

    actions: {
        setCurrentUser(uid: string) {
            this.currentUser = uid
        },
        login(): void {
            this.isAuth = true
        },
        logout(): void {
            sessionStorage.clear()
            this.isAuth = false
        },

        setErrors(val: string): void {
            this.errors = val
        }
    }
})
