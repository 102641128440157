<template>
    <div class="wrapper w-full">
        <button @click="handleClick" class="save-button w-full">
            <div class="flex items-center justify-center">
                <img  v-if="props.icon" class="pt-[1.5px] " :src="require(`../../assets/${props.icon}.svg`)" /><span class=" font-righteous text-[13px]">{{text}}</span>
            </div>
        </button>
    </div>
</template>

<script setup>
const props = defineProps(['text', 'action', 'icon'])

const emit = defineEmits(['click'])

const handleClick = () => {
    emit('click')
}
</script>

<style scoped>
.save-button {
    position: relative;
    padding: 10px 20px;
    background-color: #444;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 14px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0px 0px 32px 0px #E99D1726;

box-shadow: 0px 0px 12px 0px #E99D1726;

}

.wrapper {
    display: flex;
    position: relative;
    border-radius: 17px;
    padding: 3px;
    background: linear-gradient(90deg, #ffbf00, #ff8000);
}
</style>
