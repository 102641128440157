<template>
    <div class="text-primary w-full">
        <div class=" h-full outerbox rounded-l-xl">
            <div class="px-14 pt-9">
                <carrouselVue :images="images" />
            </div>
            <div class="grid grid-cols-4 gap-2 px-14 pt-6">
                <div>
                    <button
                        class="gradient-box h-full w-full aspect-square welcome-border aspect-h-1 aspect-w-1 rounded-lg relative">
                        <div class="inner-box flex flex-col welcome-inner box rounded-lg">
                            <div><img class="pt-3" src="../assets/presentos1.png" /></div>
                            <div class="pb-9 text-xl pl-6 flex w-full font-righteous">
                                Welcome Package
                            </div>
                        </div>
                    </button>
                </div>
                <div>
                    <div class="h-full grid grid-rows-2 gap-2">
                        <div>
                            <button class="gradient-box w-full aspect-2/1 vip-border rounded-lg relative">
                                <div class="inner-box grid grid-cols-2 vip-inner box rounded-lg">
                                    <div class="text-xl font-righteous w-full text-start pl-5">
                                        <div>The Gamehub</div>
                                        <div style="color: #3fa8f4">VIP Club</div>
                                    </div>
                                    <div class="text-xl flex w-full font-righteous relative">
                                        <img class="absolute left-2 bottom-12" src="../assets/mini-gem.png" />
                                        <img class="pl-2" src="../assets/dashgemsos.png" />
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div>
                            <button class="gradient-box w-full aspect-2/1 loyalty-border rounded-lg relative">
                                <div class="inner-box flex loyalty-inner box rounded-lg">
                                    <div class="pl-3 pr-8">
                                        <img class="w-[120px]" src="../assets/medlio-1.png" />
                                    </div>
                                    <div
                                        class="text-xl inline-flex flex-col justify-start w-full font-righteous relative">
                                        <span class="block text-start">Loyalty </span><span
                                            class="block text-start">Program</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        class="gradient-box h-full w-full aspect-square deposit-border aspect-h-1 aspect-w-1 rounded-lg relative">
                        <div class="inner-box flex flex-col deposit-inner box rounded-lg">
                            <div><img class="pt-3" src="../assets/chestoros.png" /></div>
                            <div class="pb-9 text-xl pl-6 flex w-full font-righteous">
                                Deposit Bonus
                            </div>
                        </div>
                    </button>
                </div>
                <div class="h-full grid grid-rows-2 gap-2">
                    <div>
                        <button class="gradient-box w-full aspect-2/1 refer-border rounded-lg relative">
                            <div class="inner-box grid grid-cols-2 refer-inner box rounded-lg">
                                <div class="pl-6 text-start w-full font-righteous text-xl">
                                    <div>Refer</div>
                                    <div>and earn</div>
                                </div>
                                <div class="flex w-full">
                                    <img src="../assets/chest-1.png" />
                                </div>
                            </div>
                        </button>
                    </div>
                    <!-- <div>
                        <button
                            class="gradient-box w-full aspect-2/1 promotions-border rounded-lg relative"
                        >
                            <div class="inner-box grid grid-cols-2 promotions-inner box rounded-lg">
                                <div class="pl-6 text-start w-full font-righteous text-xl">
                                    <div>Promotions</div>
                                </div>
                                <div class="flex w-full">
                                    <img src="../assets/bag-coins.png" />
                                </div>
                            </div>
                        </button>
                    </div> -->
                </div>
            </div>
            <!-- First Row of games categories-->
            <div class="flex px-14 pt-3 justify-start items-center text-xl font-righteous pb-2">
                <img class="max-h-5 pr-1" src="../assets/darts.png" />
                <span class="text-white pr-1">Live </span><span class="text-[#00e6f6]">Wins</span>
            </div>
            <div class="px-5 pt-4">
                <div class="infinite-container pb-6 mx-10">
                    <div class="image-row" ref="imageRow" :style="{ animationDuration: animationDuration + 's' }">

                        <button @click="openIframe(item.gameId)" v-for="(item, index) in repeatedItems && repeatedItems"
                            :key="index" :style="{ flexShrink: 0 }" class=" flex-shrink-0 w-1/7 px-1 ">
                            <div v-if="item.amount > 0"></div>
                            <img :ref="el => (imageRefs[index] = el)"
                                :src="`https://client.qtlauncher.com/images/?id=${item.gameId}_en_US&type=logo-square&height=260&width=210`"
                                class=" rounded-md" alt="Player Image" />
                            <div class="text-start font-bold text-xs pt-1 text-white">{{ item.playerId.length > 9 ?
                                item.playerId.slice(0, 9) + '...' : item.playerId }}</div>

                            <div class="text-start font-bold text-green-500 text-xs">{{ item.amount.toFixed(2) }} USD
                            </div>


                        </button>

                    </div>
                </div>
            </div>
            <div class="flex ml-12 items-center justify-start text-xl font-righteous">
                <img style="    width: 36px; 
    height: 36px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span style="color: #00e6f6">Tournaments</span>

            </div>
            <div class="mx-14">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="setModal(true, 'search-modal', '', '', true, tournament)"
                        class="flex-shrink-0 w-1/2 gap-2  " :key="i" v-for="(tournament, i) in tournaments">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="tournament.image &&
                                tournament.image" alt="" />

                        </div>
                    </button>

                </div>
            </div>
            <div class="px-14 pb-2 flex justify-between w-full">
                <div class="flex justify-start items-center text-xl font-righteous pb-2">
                    <img class="max-h-5 pr-1" src="../assets/console.png" />
                    <span class="text-white pr-1">Top </span><span style="color: #00e6f6">Providers</span>
                </div>
                <div class="flex flex-end">

                    <button :class="currentIndex != 0
                        ? 'border-[#4af3ff] bubble-inner-2 border-[2px] '
                        : 'border-profile-deposit bg-profile-deposit border-[2px] '
                        " class="rounded-xl w-11 ml-2 aspect-square" :disabled="currentIndex == 0
                            " @click="prevProvider">
                        <div class="flex justify-center">
                            <img :class="currentIndex != 0 ? 'filter-white' : ''
                                " src="../assets/Vector.png" />
                        </div>
                    </button>
                    <button @click="nextProvider" :disabled="currentIndex >= maxIndex" :class="currentIndex < maxIndex
                        ? 'border-[#4af3ff] bubble-inner-2 border-[2px]'
                        : 'border-profile-deposit bg-profile-deposit border-[2px]'
                        " class="rounded-xl w-11 ml-1 aspect-square">
                        <div class="flex justify-center items-center">
                            <img :class="currentIndex < maxIndex
                                ? 'filter-white'
                                : ''
                                " class="rotate-180" src="../assets/Vector.png" />
                        </div>
                    </button>
                </div>

            </div>
            <div class="flex gap-2 px-14 py-5">
                <button @click="setModal(true, 'search-modal', 'provider', provider.id)"
                    class="border-profile-deposit bg-profile-deposit border-[2px] rounded-xl flex items-center w-[14.28571%] justify-center"
                    :key="i" v-for="(provider, i) in visibleProviders">
                    <div class="text-[#424D71] text-[11px] font-roboto">
                        <img class="rounded-lg " :src="`https://lobby.qtlauncher.com/images/?id=${provider.id}_en_US&type=providers&version=1728053882027&theme=dark&format=avif${provider.id == 'SLG' ? '&newItem=true' : ''}
`" alt="" />
                        <!-- <div class="pb-3">
                                {{ getNumberGamesByProvider(provider) }} games
                            </div> -->
                    </div>
                </button>
            </div>
            <div class="flex mx-[53px] items-center justify-start text-xl font-righteous">
                <img style="    width: 36px; 
    height: 36px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Last </span><span style="color: #00e6f6">Played</span>

            </div>
            <div class="mx-14">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in dashboardStore.lastPlayed">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>


            <div class="grid grid-rows-2 px-[54px] pt-3">
                <div class="flex justify-start text-xl font-righteous pb-2">
                    <img src="../assets/card-games-alt.png" />
                    <span class="text-white pr-1">All </span><span style="color: #00e6f6">Games</span>
                </div>
                <div class="flex justify-between">
                    <div style="color: #424d71"
                        class="flex text-sm max-w-2xl items-center gap-4 rounded-full font-righteous bg-profile-deposit border-profile-deposit">
                        <div class="min-w-16 clickable" :class="category == 0 ? 'bubble-border h-full' : null">
                            <button :class="category == 0
                                ? 'bubble-inner h-full w-full flex items-center justify-center text-white'
                                : null
                                " @click="setCategory(0)">
                                All
                            </button>
                        </div>
                        <button :class="category == 1
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : null
                            " @click="setCategory(1)" class="min-w-16">
                            Top Games
                        </button>
                        <button :class="category == 2
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : null
                            " class="min-w-16" @click="setCategory(2)">
                            Newly Added
                        </button>
                        <button :class="category == 3
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : null
                            " @click="setCategory(3)" class="min-w-16">
                            Slots
                        </button>
                        <button :class="category == 4
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : null
                            " @click="setCategory(4)" class="min-w-16">
                            Live Casino
                        </button>
                        <button :class="category == 5
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : null
                            " @click="setCategory(5)" class="min-w-16">
                            Bonus Buy
                        </button>
                        <button :class="category == 6
                            ? 'bubble-inner h-full flex items-center justify-center px-3 text-white'
                            : 'pr-4'
                            " @click="setCategory(6)" class="min-w-16">
                            <span>Table Games</span>
                        </button>
                    </div>
                    <div class="flex flex-end">
                        <!-- <button class="max-w-32 rounded-full flex w-full flex-end font-righteous">
                            <div
                                class="w-full h-full flex items-center rounded-full bg-profile-deposit border-profile-deposit"
                            >
                                <div class="flex pl-2 pr-2"><img src="../assets/eye.png" /></div>
                                 <span class="pr-2" style="color: #424d71">See All</span> 
                                <span
                                    class="px-1"
                                    style="
                                        font-size: 9px;
                                        color: #8690bf;
                                        background-color: #2a345d;
                                        border-radius: 5px;
                                    "
                                    >{{ dashboardStore.gameList.totalCount }}</span
                                >
                            </div>
                        </button> -->

                        <button :class="dashboardStore.pageDashboardReal != 0
                            ? 'border-[#4af3ff] bubble-inner-2 border-[2px] '
                            : 'border-profile-deposit bg-profile-deposit border-[2px] '
                            " class="rounded-xl w-11 ml-2 aspect-square" :disabled="dashboardStore.isLoading == true ||
                                dashboardStore.pageDashboardReal == 0
                                " @click="prevPage">
                            <div class="flex justify-center">
                                <img :class="dashboardStore.pageDashboardReal != 0 ? 'filter-white' : ''
                                    " src="../assets/Vector.png" />
                            </div>
                        </button>
                        <button @click="nextPage" :disabled="dashboardStore.isLoading == true ||
                            dashboardStore.pageDashboardReal >= itemBatches.length ||
                            itemBatches[dashboardStore.pageDashboard].length != 14
                            " :class="dashboardStore.pageDashboardReal < itemBatches.length &&
                                itemBatches[dashboardStore.pageDashboard].length == 14
                                ? 'border-[#4af3ff] bubble-inner-2 border-[2px]'
                                : 'border-profile-deposit bg-profile-deposit border-[2px]'
                                " class="rounded-xl w-11 ml-1 aspect-square">
                            <div class="flex justify-center items-center">
                                <img :class="dashboardStore.pageDashboardReal < itemBatches.length &&
                                    itemBatches[dashboardStore.pageDashboard].length == 14
                                    ? 'filter-white'
                                    : ''
                                    " class="rotate-180" src="../assets/Vector.png" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="overflow-hidden mx-12 slider-container">
                <transition-group :style="{
                    transform: 'translateX(-' + dashboardStore.pageDashboardReal * 100 + '%)'
                }" name="slider" tag="div" class="flex pt-3 mb-8 slider">
                    <!-- :style="sliderStyle" :style="setReset" later -->
                    <div class="batch" v-for="(batch, batchIndex) in itemBatches" :key="batchIndex">
                        <button class="flex flex-wrap w-[14.28571%] px-1 pb-2 slider-item" v-for="item in batch"
                            :key="item.id" :disabled="!auth.currentUser?.uid" @click="openIframe(item)">
                            <img class="rounded-md" v-lazy="dashboardStore.category != 1 &&
                                item.images &&
                                item.images.length > 2
                                ? item.images[2].url.concat('&height=388&width=318')
                                : `https://client.qtlauncher.com/images/?id=${category != 1 ? item.id : item}_en_US&type=logo-square&height=388&width=318`
                                " alt="" />
                        </button>
                    </div>
                </transition-group>
            </div>



            <div v-if="iframeOpened">
                <iframe class="absolute left-0 top-0 h-[100vh] w-[100vw] z-50" :src="dashboardStore.gameUrl"
                    frameborder="0"></iframe>
            </div>
            <div v-if="dashboardStore.sideGameOpened">
                <iframe class="absolute left-0 top-0 h-[100vh] w-[100vw] z-50" :src="dashboardStore.gameUrl"
                    frameborder="0"></iframe>
            </div>

            <div style="margin: 0 23px;" class="border-b-[0.2px] px-10  pb-8 border-[#696969c8]"></div>
            <div class="flex flex-row px-[230px] pt-7"> <span class="py-3  text-white text-xs ">The Gamehub LLC is an
                    operator
                    under the QTech
                    gaming
                    platform
                    gaming
                    operated by
                    Mitratech
                    Curacao B.V., a company incorporated under the laws of Curaçao with company registration
                    number
                    146806. Its registered office is located at Zuikertuintjeweg Z/N (Zuikertuin Tower),
                    Willemstad,
                    Curaçao. The company is licensed by Cyberluck Curaçao N.V. (Curaçao eGaming) under
                    license
                    number 1668/JAZ, authorized by the Government of Curaçao.</span></div>
            <div class="flex flex-row justify-around items-center px-[230px]">
                <div><img class="pt-1" heigh="119" src="../assets/the-game-hub.png" /> </div>
                <div class="flex">
                    <a class="pr-3" href="https://www.gambleaware.org/home"><img src="../assets/begamble.png"
                            alt=""></a>
                    <img src="../assets/+18.png" alt="">
                </div>

                <div class="text-white">Support: <a href="t.me/TheGameHubSupportBot"
                        target="_blank">@TheGameHubSupportBot</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import { useAuthStore } from '@/store/authStore'
import { computed, ref, onMounted, onUnmounted, nextTick, watch } from 'vue'
import carrouselVue from './carrouselVue.vue'
import { arrayPos, createArraySlice } from '@/helper/helper'
import axios from 'axios'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { auth } from '@/main'

import { db } from '../main'
import { createClient } from '@supabase/supabase-js';
const category = computed(() => dashboardStore.category)
const supabaseUrl = 'https://ynmpadijweokavjhrsrb.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlubXBhZGlqd2Vva2F2amhyc3JiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY5MTU5NTEsImV4cCI6MjA0MjQ5MTk1MX0.9Wjn_8kAiDa0qQKNOjf_h_RttRCwYV8byUJJN940hFM'; // Replace with your actual key
const supabase = createClient(supabaseUrl, supabaseAnonKey);
const games = ref([]);
const imageRefs = ref([]);
const error = ref(null);
const imageRow = ref(null);
let saveInterval
// Define props
const maxIndex = computed(() => {

    return Math.ceil(gameProviders.length / itemsPerView.value) - 1;

})

// Use the store
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()
// const baseDataRef = doc(db, 'aBase/baseInf')
//  const baseData = await getDoc(baseDataRef)
//  const coinSupported = baseData.data().coinSupported

// Define a computed property for category
const iframeOpened = ref(false)
const animationDuration = ref(10)
const params = ref({ size: 14 })
// Define the method to set the category
const reset = ref(null)
const gameProviders = [

    { id: 'SLG' },
    {
        name: 'Pragmatic Play Casino',
        id: 'PPC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    },
    {
        name: 'Evolution Gaming',
        id: 'EVO',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evolution.svg'
    },
    {
        name: 'Hacksaw Gaming',
        id: 'HAK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/hacksaw.svg'
    },
    {
        name: 'Spribe',
        id: 'SPB',
        img: require('@/assets/SPB.png')
    },
    { id: 'YGG' },
    { id: 'BPG' },
    {
        name: 'Habanero',
        id: 'HAB',
        img: 'https://cdn2.softswiss.net/logos/providers/white/habanero.svg'
    },
    {
        name: 'Nolimit City',
        id: 'NLC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/nolimit.svg'
    },
    {
        name: 'Thunderkick',
        id: 'TK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/thunderkick.svg'
    },
    { id: 'BTL' },
    {
        name: 'Evoplay',
        id: 'EVP',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evoplay.svg'
    },
    { id: 'EZU' },
    {
        name: 'Fantasma Games',
        id: 'FNG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/fantasma.svg'
    },
    { id: 'SWC' },
    {
        name: 'GAMEART',
        id: 'GA',
        img: 'https://cdn2.softswiss.net/logos/providers/white/gameart.svg'
    },
    {
        name: 'Pragmatic Play Live',
        id: 'PPL',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    },
    { id: 'MSG' },
    { id: 'MOB' },
    { id: 'NAG' },
    { id: 'NGE' },
    { id: 'SAG' },
    {
        name: 'Slotmill',
        id: 'SM',
        img: 'https://cdn2.softswiss.net/logos/providers/white/slotmill.svg'
    },
    { id: 'SMS' },
    { id: 'TRB' },
    { id: 'VGL' },
    {
        name: 'Wazdan',
        id: 'WAZ',
        img: 'https://cdn2.softswiss.net/logos/providers/white/wazdan.svg'
    },
    { id: 'WOO' },
    { id: '1X2' },
    { id: '7ML' },
    { id: '7MC' },
    {
        name: 'Big Time Gaming',
        id: 'BTG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/bigtimegaming.svg'
    },
    {
        name: 'NetEnt',
        id: 'NE',
        img: 'https://cdn2.softswiss.net/logos/providers/white/netent.svg'
    },
    {
        name: 'Red Tiger',
        id: 'RED',
        img: 'https://cdn2.softswiss.net/logos/providers/white/redtiger.svg'
    },
    { id: 'SWL' },
    { id: 'EYC' }
];

const showIframe = ref(true);


const images = ref([
    require('../assets/referral.png'),

    require('../assets/TOPup_bonus.png'),
    require('../assets/tournaments_casino.png'),
    require('../assets/vip_rewards.png')
])
const setCategory = val => {
    dashboardStore.resetLinkHistory()
    // Store the current category for reset purposes
    reset.value = dashboardStore.category

    // Update the category in the dashboard store
    dashboardStore.setCat(val)

    // Conditional logic based on the category value

    // Reset the dashboard page to the first page
    dashboardStore.setPageDashboard(0)
}

const calculateScrollAnimation = () => {
    const row = imageRow.value;
    if (row) {
        nextTick(() => {
            const totalWidth = row.scrollWidth; // Calculate total width of child elements
            const duration = totalWidth / 50; // Adjust scrolling speed (50px/s)
            console.log("Duration:", duration, "Total Width:", totalWidth);

            // Update CSS variables for animation
            row.style.setProperty("--scroll-distance", `${totalWidth}px`);
            row.style.setProperty("--scroll-duration", `${duration}s`);

            // Update local state for reactivity (if necessary)
            animationDuration.value = duration;
        });
    }
}

let currentPage = ref(1)
const itemsPerPage = 7

const setModal = async (val, name, list, provId) => {
    dashboardStore.setModalOpen(val, name)
    if (list == 'provider') {
        dashboardStore.setSelectedPV(provId)
    }
}

const aggregatedBetsAndPayouts = computed(() => {
    console.log("BETS", dashboardStore.livebets.length != 0 ? dashboardStore.livebets : 0)
    // Filter out transactions where type is not ROLLBACK or other disallowed types.
    const filteredTransactions = dashboardStore.livebets.length != 0 && dashboardStore.livebets.filter(
        (transaction) => transaction.type === "PAYOUT"
    );
    console.log("TRANsss", filteredTransactions)

    const reversedArray = filteredTransactions && [...filteredTransactions].reverse();
    console.log("TRANSACTIOS", reversedArray)
    // Reduce to aggregate by playerGameRoundId
    const aggregated = reversedArray && reversedArray.reduce((acc, transaction) => {
        const roundId = transaction.playerGameRoundId;
        console.log("TRAN", transaction)
        // Ensure we have an entry for this roundId
        if (!acc[roundId]) {
            acc[roundId] = {
                playerGameRoundId: roundId,    // Include playerGameRoundId for clarity
                playerId: transaction.username,  // Store playerId
                gameId: transaction.gameId,      // Store gameId
                amount: 0,

            };
        }

        // Accumulate based on type

        if (transaction.type === "PAYOUT" && transaction.amount > 0) {
            console.log("AMOUNT", transaction.amount)
            acc[roundId].amount += parseFloat(transaction.amount);
        }
        console.log("ACC", acc)

        return acc;
    }, {});
    console.log("AGG", aggregated)
    console.log("AGG", aggregated && Object.values(aggregated).filter(entry => entry.payout !== 0))
    // Convert the aggregated object into an array of objects and filter out those with payout 0
    return aggregated && Object.values(aggregated).filter(entry => entry.payout !== 0);
});


const repeatedItems = computed(() => {
    const items = Array.isArray(aggregatedBetsAndPayouts.value) ? aggregatedBetsAndPayouts.value : [];
    // Duplicate the array for seamless animation
    return [...items, ...items];
})

const total_pages = computed(() => {
    return Math.ceil(gameProviders.length / itemsPerPage)
})
const visibleProviders = computed(() => {
    const start = currentIndex.value * itemsPerView.value;
    return gameProviders.slice(start, start + itemsPerView.value);
})
const paginated_items = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return gameProviders.slice(startIndex, endIndex)
})
function getPaginatedItems(array, page) {
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return array.slice(startIndex, endIndex)
}

function goToNextPage() {
    console.log("TOTALPAGES", currentPage.value, total_pages.value)
    if (currentPage.value < total_pages.value) {
        currentPage.value++
    }
}
const nextProvider = () => {
    if (currentIndex.value < maxIndex.value) {
        currentIndex.value++;
    }
}

const prevProvider = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    }
}

const getNumberGamesByProvider = provider => {
    return games_.value.filter(item => item.provider_id == provider.id).length
}

function goToPreviousPage() {
    if (currentPage.value > 1) {
        currentPage.value--
    }
}
const page_provider = ref(0)
const nextPage = async () => {
    if (category.value == 0) {
        offset_all.value += limit
        await allGames()
    }
    if (category.value == 2) {
        offset_new.value += limit
        await newGames()

    }
    if (category.value == 3) {
        offset_slot.value += limit
        await slotGames()

    }

    if (category.value == 4) {
        offset_casino.value += limit
        await liveCasino()
    }

    if (category.value == 5) {
        offset_bonus.value += limit
        await bonusGames()
    }
    if (category.value == 6) {
        offset_table.value += limit
        await tableGames()
    }

    if (dashboardStore.pageDashboardReal == itemBatches.value.length) dashboardStore.hasNext = false
    dashboardStore.plusPageDashboard(1)
}

const prevPage = () => {
    dashboardStore.plusPageDashboard(-1)
}


const itemBatches = computed(() => {
    console.log("ITEMS", items.value)
    if (items.value) {
        const batchSize = 14
        const batches = []
        console.log("LEN", items.value.length, items.value)
        for (let i = 0; i < items.value.length; i += batchSize) {
            batches.push(items.value.slice(i, i + batchSize))
        }
        console.log("BATCHES", batches)
        return batches
    } else return null
})
const setReset = computed(() => {
    if (reset.value != dashboardStore.category) {
        return {
            transform: `translateX(-${currentIndex.value * 100}%)`,
            transition: 'transform 0.5s ease' // Apply transition effect
        }
    } else {
        return {
            transform: `translateX(-0%)`,
            transition: 'transform 0.5s ease' // Apply transition effect
        }
    }
})
const items = computed(
    () => {




        if (category.value == 0) {
            console.log("ITEMSS", all_games)
            return all_games.value

        }

        if (category.value == 1) {
            return topGames.value
        }

        if (category.value == 2) {

            // Create a copy of the array and then sort it

            return new_games.value
        }

        if (category.value == 3) {
            return slot_games.value
        }

        if (category.value == 4) {
            return live_casino.value
        }
        if (category.value == 5) {
            return bonus_games.value
        }

        if (category.value == 6) {
            return table_games.value
        }

        return all_games.value
    }
    // For other categories, return the unfiltered game list
)

const numitems = ref(0)
const games_ = ref([]) // Reactive variable to store the games data
const error_ = ref(null);

let intervalId;

// Fetch games from Supabase
const fetchGames = async () => {
    dashboardStore.setLoading(true)

    console.log("LOADING", dashboardStore.loading)
    const { data: gamesData, error: fetchError } = await supabase
        .from('qt_games')
        .select('*');
    console.log("gamesdata", gamesData)
    if (fetchError) {
        error_.value = fetchError.message;
    } else {
        console.log("data", gamesData)
        games_.value = gamesData;
    }
    dashboardStore.setLoading(false)

};
const isMounted = ref(false);

function mergeUnique(arr1, arr2, key) {
    const uniqueArray = [...arr1];

    arr2.forEach(item => {
        if (!arr1.some(existingItem => existingItem[key] === item[key])) {
            uniqueArray.push(item);
        }
    });
    console.log("uniqueArray", arr1, arr2)
    console.log("uniqueArray", uniqueArray)
    return uniqueArray;
}

const openIframe1 = item => {
    showIframe.value = true
    dashboardStore.getGameUrl(item.id)
    if (dashboardStore.showIframe == false) {
        dashboardStore.setShowIframe(true)

    }
}
onMounted(async () => {
    getTournaments()


    calculateScrollAnimation();
    watch(
        () => repeatedItems.value,
        () => {
            calculateScrollAnimation(); // Recalculate if repeatedItems changes
        },
        { immediate: true }
    );

    if (sessionStorage.getItem('login') == 'true' || sessionStorage.getItem('login') == true) {
        authStore.login()
        console.log("LOGIN")
    }

    await fetchGames()
    const waitForCurrentUser = setInterval(() => {
        if (authStore.currentUser?.uid) {
            // Stop the interval
            clearInterval(waitForCurrentUser);
            console.log("UID", auth.currentUser?.uid)
            authStore.setCurrentUser(auth.currentUser?.uid)
            // Logic to execute when currentUser is not null
            console.log('User UID:', authStore.currentUser.uid);
        }
    }, 100); // Check 
    dashboardStore.getLastPlayed()
    tableGames()
    newGames()
    slotGames()
    liveCasino()
    bonusGames()
    allGames()
    calculateScrollAnimation();
    //await fetchGames()
    if (dashboardStore.gameUrl == '') {
        console.log("COMPONENT MOUNETD")
        dashboardStore.getGameTransactions()
        dashboardStore.getMostPopularList(null, '', 'dashboard', '')
        dashboardStore.getBalance()


        intervalId = setInterval(() => {
            dashboardStore.getGameTransactions()
        }, 40000); // 120000 milliseconds = 2 minutes
        isMounted.value = true
    }
})
const itemsPerView = ref(7)

const currentIndex = ref(0)

const resetStyle = computed(() => {
    return {
        transform: `translateX(0%)`,
        transition: 'transform 0.5s ease' // Apply transition effect
    }
})
onUnmounted(() => {
    // Clear the interval when the component is unmounted to prevent memory leaks
    if (saveInterval) {
        clearInterval(saveInterval)
    }
    clearInterval(intervalId);
    // Stop the Web Worker
})

const getTournaments = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_tournaments')
            .select('*') // Select all columns or specific ones
        if (error) {
            console.error('Error fetching tournaments', error)
            return []
        }
        console.log("tournaments", data)
        tournaments.value = data
        return data
    } catch (error) {
        console.error('Error fetching tournaments:', error)
        return []
    }
}
const topGames = computed(() => {

    const ids = dashboardStore.popularGames.map(item => item.id)
    return ids.sort((a, b) => {
        const indexA = providerOrder.indexOf(a.provider_id);
        const indexB = providerOrder.indexOf(b.provider_id);

        // Items in providerOrder should come first, sorted by their index
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }
        // Items in providerOrder come before items not in the list
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // For items not in providerOrder, keep their original order
        return 0;
    });
})

const all_games = ref([])
const table_games = ref([])
const new_games = ref([])
const slot_games = ref([])
const tournaments = ref([])

const live_casino = ref([])
const bonus_games = ref([])
const providerOrder = ['HAK', 'EVO', 'PPC'];
const allowedProviderIds = [
    'SLG',
    'PPC',
    'EVO',
    'HAK',
    'SPB',
    'BPG',
    'HAB',
    'NLC',
    'TK',
    'BTL',
    'EVP',
    'EZU',
    'FNG',
    'SWC',
    'GA',
    'PPL',
    'MSG',
    'MOB',
    'NAG',
    'NGE',
    'SAG',
    'SM',
    'SMS',
    'TRB',
    'VGL',
    'WAZ',
    'WOO',
    '1X2',
    '7ML',
    '7MC',
    'BTG',
    'NE',
    'RED',
    'SWL',
    'EYC'
]
const limit = 14; // Number of items to fetch
const offset_new = ref(0); // Starting index for fetching games
const offset_table = ref(0); // Starting index for fetching games
const offset_slot = ref(0); // Starting index for fetching games
const offset_casino = ref(0); // Starting index for fetching games
const offset_bonus = ref(0); // Starting index for fetching games
const offset_all = ref(0); // Starting index for fetching games

const allGames = async () => {
    const { data, error } = await supabase
        .from('qt_games')
        .select('*').range(offset_all.value, offset_all.value + limit - 1)
    // Sort by release_date in descending order

    if (error) {
        console.error('Error fetching sorted games:', error);
    } else {

        all_games.value = mergeUnique(all_games.value, data, 'id')
        console.log('Sorted games:', data, all_games.value);

    }
}

const newGames = async () => {
    const { data, error } = await supabase
        .from('qt_games')
        .select('*') // Select all columns or specific ones
        .in('provider_id', allowedProviderIds)
        .order('release_date', { ascending: false }).range(offset_new.value, offset_new.value + limit - 1)

    if (error) {
        console.error('Error fetching sorted games:', error);
    } else {
        console.log('Sorted games:', data);
        new_games.value = mergeUnique(new_games.value, data, 'id')
    }
}

const tableGames = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%TABLEGAME%')
            .in('provider_id', allowedProviderIds)
            .range(offset_table.value, offset_table.value + limit - 1)// Case-insensitive pattern match
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        table_games.value = mergeUnique(table_games.value, data, 'id')

    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}

const slotGames = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%SLOT%')
            .in('provider_id', allowedProviderIds)
            .range(offset_slot.value, offset_slot.value + limit - 1)// Case-insensitive pattern match
        console.log("SLOT", data)
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        slot_games.value = mergeUnique(slot_games.value, data, 'id')
    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}

const liveCasino = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%LIVECASINO%')
            .in('provider_id', allowedProviderIds)
            .range(offset_casino.value, offset_casino.value + limit - 1)// Case-insensitive pattern match
        console.log("LIVECASINO", data)
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        live_casino.value = mergeUnique(live_casino.value, data, 'id')
    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}
const bonusGames = async () => {


    const { data, error } = await supabase
        .from('qt_games')
        .select('*')
        .in('provider_id', allowedProviderIds)

        .not('features', 'is', null) // Exclude NULL values
        .range(offset_bonus.value, offset_bonus.value + limit - 1)// Case-insensitive pattern match

    const data_ = data.filter((item) => item.features && item.features.some(feature => feature.id == 'BONUS_BUY'))
    const result = data_.slice(0, 14)
    if (error) {
        console.error('Error fetching games with BONUS_BUY feature:', error);
    } else {
        bonus_games.value = mergeUnique(bonus_games.value, data, 'id')

        console.log('Games with BONUS_BUY feature:', data);
    }

}





const sliderStyle = computed(() => {
    return {
        transform: `translateX(-${currentIndex.value * 100}%)`,
        transition: 'transform 0.5s ease' // Apply transition effect
    }
})

const canSlideLeft = computed(() => {
    return currentIndex.value > 0
})

const canSlideRight = computed(() => {
    if (dashboardStore.gameList.items)
        return (currentIndex.value + 1) * 7 < dashboardStore.gameList.items.length
    else return false
})

const openIframe = item => {
    dashboardStore.getGameUrl(item.id)
    if (!iframeOpened.value) {
        iframeOpened.value = true
    }
}

const slideLeft = () => {
    currentIndex.value--
}

const slideRight = () => {
    currentIndex.value++
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.infinite-container {
    overflow: hidden;

    /* Adjust based on your layout */
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 * var(--scroll-distance)));
    }
}

.image-row {
    display: flex;
    white-space: nowrap;
    animation: scroll linear infinite;
    animation-duration: var(--scroll-duration, 10s);
    /* Default duration of 10s if not set */
}

.player-image {
    width: 100px;
    /* Adjust based on image size */
    height: 100px;
    margin-right: 10px;
}



.image-border {
    border-radius: 20px;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.outerbox {
    border-color: theme('colors.border-dropdown-1');
    background: theme('colors.dropdown-1');
}

.slider-enter-active,
.slider-leave-active {
    transition: transform 0.5s ease;
}

.flex {
    display: flex;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.slider-enter-from,
.slider-leave-to {
    transform: translateX(100%);
}

.slider-leave-from,
.slider-enter-to {
    transform: translateX(0);
}

.slider {
    transition: transform 0.5s ease;
}

.slider-item {
    text-align: center;
}
.bubble-border {
    padding: 2px;
    border-radius: 22px;
    background: linear-gradient(180deg, #4af3ff 0%, #1d99a2 100%);
}

.bubble-inner-2 {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
}
.bubble-inner {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
    border-radius: 20px;
}

.dollar-text {
    background-image: linear-gradient(to bottom, #4af3ff, #1d99a2);
}

.welcome {
    background: linear-gradient(to top right, #853d32, #2b2f4e);
}

.welcome::before {
    content: '';
    background: linear-gradient(180deg, #4e3148 50%, #121b3e);
    box-shadow: 0px 0px 40px 0px #e75ec940;
}

.welcome-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #853d32 100%);
}

.welcome-inner {
    background: linear-gradient(135deg, #2b0033, #6e007a);
}

.deposit-border {
    background: linear-gradient(to bottom left, #2b2f4e 0%, #7b5635 100%);
}

.refer-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #50357b 100%);
}

.refer-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #460e68 100%);
}

.promotions-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #6d3058 100%);
}

.promotions-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #4a1a34 100%);
}

.deposit-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #403135 100%);
} /* css error */

.vip-border {
    background: linear-gradient(180deg, #2b314e 0%, #1d8cca 100%);
}

.vip-inner {
    background: linear-gradient(180deg, #192b5b 19.44%, #044966 114.96%);
}
.batch {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
}
.loyalty-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #853d32 100%);
}

.loyalty-inner {
    background: linear-gradient(to bottom left, #121b3e 6.58%, #4e3133 100%);
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.gradient-box {
    border-radius: 20px; /* Adjust this value to change the border radius */
    padding: 2px; /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Transition classes for entering and leaving */
.slider-enter-active,
.slider-leave-active {
    transition: transform 0.5s ease;
}

.slider-enter-from,
.slider-leave-to {
    transform: translateX(100%);
}

.slider-leave-from,
.slider-enter-to {
    transform: translateX(0);
}

.slider {
    transition: transform 0.5s ease;
}

.slider-item {
    text-align: center;
}
.inner-box {
    width: 100%;
    height: 100%;
    border-radius: 18px; /* Slightly less than the outer box to fit inside */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
}

.left-full {
    left: -100%;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    height: 100%;
}

.filter-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(55%) saturate(150%) hue-rotate(242deg)
        brightness(110%) contrast(100%);
}

.fullscreen-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 2s ease-in-out;
}

.carousel-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-cover: cover;
}
</style>
