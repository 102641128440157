// src/main.ts
import { createApp, reactive, ref } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import axios from 'axios'
import './assets/tailwind.css' // Import Tailwind CSS
import { useDashboardStore } from './store/dashboardStore'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword
} from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { doc, getDoc, collection } from 'firebase/firestore'
import { getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

interface ServiceWorkerRegistrati {
    sync: {
        register(tag: string): Promise<void>
    }
}
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
import VueLazyload from 'vue-lazyload-next'
import { useAuthStore } from './store/authStore'

const app_ = initializeApp(firebaseConfig)

initializeApp(firebaseConfig)
const nextLink = ref('')
const app = createApp(App)
app.use(createPinia())

export const db = getFirestore(app_) //to pass in the doc(db, "path");
export const auth = getAuth(app_)
export const Functions = getFunctions(app_) //to pass in the HttpsCallable
export const analytics = getAnalytics(app_)
const authStore = useAuthStore()

onAuthStateChanged(auth, async user => {
    try {
        if (user) {
            console.log('User is logged in:', user.uid)
            sessionStorage.setItem('login', 'true')
            dashboardStore.setComponentKey()
            try {
                const playerUid = user.uid

                // Fetch user data
                const userRef = doc(db, `users-casino/${playerUid}`)
                const userData = await getDoc(userRef)
                console.log('User Data:', userData.data())

                // Fetch deposit history
                const depRef = collection(db, `users/${playerUid}/np-dep-history`)
                const q = query(depRef, orderBy('date', 'desc'), limit(10))
                const depositsDocs = await getDocs(q)
                const depositDocs = depositsDocs.docs.map((doc: any) => ({
                    id: doc.id,
                    ...doc.data()
                }))

                dashboardStore.setDepositDocs(depositDocs)

                console.log('Deposit Docs:', depositDocs)

                // Fetch withdrawal history
                const withRef = collection(db, `withdrawals`)
                const q2 = query(
                    withRef,
                    where('uid', '==', playerUid),
                    orderBy('date', 'desc'),
                    limit(10)
                )
                const withDocs = await getDocs(q2)
                const docsData = withDocs.docs.map((doc: any) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                console.log('Withdrawal Docs:', docsData)
            } catch (dataError) {
                console.error('Error fetching Firestore data:', dataError)
            }
        } else {
            console.log('User is not logged in')
        }
    } catch (authError) {
        console.error('Error in onAuthStateChanged:', authError)
    }
})

// export const Functions = getFunctions(app_) //to pass in the HttpsCallable

// export const analytics = getAnalytics(app_)

let balance: any = {}
let depositsDocs: any = ''
let withDocs: any = ''
let coinSupported = [] as any // Declare the variable outside the function for exporting
let qtSelectedCurrency: any = ''
const baseDataRef = doc(db, 'aBase/baseInfo')
// try {
//     const baseData = await getDoc(baseDataRef)

//     coinSupported = baseData.data()!.coinSupported
// } catch (error) {
//     console.error('Error fetching document:', error)
// }
export const coinSupported_ = coinSupported

import { createClient } from '@supabase/supabase-js'
console.log('YES1')
const supabaseUrl = 'https://ynmpadijweokavjhrsrb.supabase.co'
const supabaseAnonKey =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlubXBhZGlqd2Vva2F2amhyc3JiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY5MTU5NTEsImV4cCI6MjA0MjQ5MTk1MX0.9Wjn_8kAiDa0qQKNOjf_h_RttRCwYV8byUJJN940hFM'
export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const exampleRegister = async (email: string, passw: string) => {
    try {
        await createUserWithEmailAndPassword(auth, email, passw)
    } catch (error) {
        console.log('ERROR', error)
        alert(error)
    }
}

// Use Vue Router
app.use(router)
console.log('YES2')

// Use vue-lazyload-next
app.use(
    VueLazyload,
    app.use(VueLazyload, {
        preLoad: 1.3, // Preload images that are just off-screen (adjust as needed)

        attempt: 1, // Number of retry attempts
        observer: true, // Use IntersectionObserver
        observerOptions: {
            root: null, // Use the viewport as the root
            rootMargin: '0px 100% 0px 0px', // Extend observation area to the right
            threshold: 0.1 // Trigger loading earlier
        }
    })
)

app.mount('#app')

const balanceState = reactive({
    balance: null
})
console.log('YES3')

app.use(createPinia())
export async function fetchUserBalance() {
    const playerUid = auth.currentUser ? auth.currentUser.uid : null
    if (playerUid) {
        const userRef = doc(db, `users-casino/${playerUid}`)
        try {
            const userData = await getDoc(userRef)
            if (userData.exists()) {
                balanceState.balance = userData.data().balance
            } else {
                console.log('User document does not exist')
            }
        } catch (error) {
            console.error('Error fetching user document:', error)
        }
    }
}

// Fetch balance on app load or when authentication state changes
fetchUserBalance()
console.log('YES4')

// Provide the balance state to the rest of the app
app.provide('balanceState', balanceState)
const dashboardStore = useDashboardStore()
export const exampleLogin = async (email: string, passw: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, passw)
            .then(async userCredential => {
                const user = userCredential.user

                dashboardStore.setErrors('')
                const playerUid = auth.currentUser?.uid // Get the UID of the authenticated user
                console.log('PLAYERUID', playerUid)
                const userRef = doc(db, `users/${playerUid}`) // 'db' should be your Firestore instance

                const userData = await getDoc(userRef)

                balance = await userData.data()?.balances

                qtSelectedCurrency = await userData.data()?.qtSelectedCurrency
                console.log('qtSee', qtSelectedCurrency)
                // Fetch the games data first
                // Fetch base data after games data is initialized
                const baseDataRef = doc(db, 'aBase/baseInfo')
                const baseData = await getDoc(baseDataRef)

                coinSupported = (await baseData.data()?.coinSupported) || {} // Assign the fetched value to coinSupported
                dashboardStore.setCoinSupported(coinSupported)
                console.log('COINSUPPPRTED', coinSupported, qtSelectedCurrency)
                dashboardStore.setLoading(false)
                console.log('LOGIN IS MADE')
                sessionStorage.setItem('login', 'true')
            })
            .catch(error => {
                console.log('ERROR', error)
                alert(error)
                const errorCode = error.code
                sessionStorage.setItem('login', 'false')
                const errorMessage = error.message

                dashboardStore.setErrors(error.code)
            })
    } catch (error) {
        //handle error
    }
}
export const logOut = async () => {
    await signOut(auth)
    sessionStorage.setItem('login', 'false')
}
console.log('YES5')

function openDatabase() {
    return new Promise((resolve, reject) => {
        // Open (or create) the database
        const request = indexedDB.open('MyGameDatabase', 1)

        // Handle the onupgradeneeded event, which is triggered when a database is created
        // or when the version number is incremented
        request.onupgradeneeded = function (event: any) {
            const db = event.target.result

            // Create the "games" object store if it doesn't exist
            if (!db.objectStoreNames.contains('games')) {
                const objectStore = db.createObjectStore('games', { keyPath: 'id' })

                // You can create indexes here if needed
                // objectStore.createIndex('name', 'name', { unique: false });
            }
        }

        // Handle the onsuccess event, which is triggered when the database is opened successfully
        request.onsuccess = function (event: any) {
            const db = event.target.result
            resolve(db) // Resolve the promise with the database instance
        }

        // Handle the onerror event, which is triggered when an error occurs while opening the database
        request.onerror = function (event: any) {
            reject('Error opening database: ' + event.target.errorCode)
        }
    })
}

console.log('YES6')

async function initApp() {
    dashboardStore.setLoading(true)
    try {
        // Open the IndexedDB database
        try {
            const db = await openDatabase()
        } catch (error) {
            console.error('IndexedDB initialization error:', error)
        }
        dashboardStore.getGameTransactions()
    } catch (error) {
        console.error('Error during app initialization:', error)
    }
    dashboardStore.setLoading(true)
}
console.log('YES7')

await initApp()

console.log('YES8')
const depRef = collection(db, `users/${auth.currentUser?.uid}/np-dep-history`)
const q = query(depRef, orderBy('date', 'desc'), limit(10))

console.log('YES8.3')
try {
    depositsDocs = await getDocs(q)
    console.log('Deposits fetched successfully:', depositsDocs.docs)
} catch (error) {
    console.error('Error fetching deposits:', error)
}
const depositDocs = depositsDocs.docs.map((doc: any) => ({
    id: doc.id, // Document ID
    ...doc.data() // Document data
}))

console.log('DEPOSIT2', depositDocs)
const withRef = collection(db, `withdrawals`)
console.log('YES8.4')
const q2 = query(
    withRef,
    where('uid', '==', auth.currentUser?.uid),
    orderBy('date', 'desc'),
    limit(10)
)
console.log('YES8.5')

withDocs = await getDocs(q2)
// Extract documents
const docsData = withDocs.docs.map((doc: any) => ({
    id: doc.id, // Document ID
    ...doc.data() // Document data
}))
console.log('deposiDocs', depositDocs)

export { coinSupported }
export { balance }
export { docsData }
export { depositDocs }
export { qtSelectedCurrency }


