<template>
    <div class="flex h-screen bg-screen overflow-hidden">
        <div class="flex h-screen overflow-y-auto no-scrollbar min-w-[296px]  ">
            <SideBarVue />
        </div>
        <div class="shrink w-full h-screen overflow-y-auto no-scrollbar">
            <div class="grid grid-rows-[auto_1fr] h-full">
                <div class="row-1">
                    <NavBarVue :key="dashboardStore.componentKey" :coinSupported="coinSupported" :balance="balance_" />
                </div>
                <div class="overflow-y-auto flex">
                    <div class="shrink w-full overflow-y-auto no-scrollbar">
                        <router-view />
                    </div>
                    <div v-if="dashboardStore.showChat == true" class="flex justify-between sticky top-0 min-w-80">
                        <ChatVue />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ChatVue from '@/components/ChatVue.vue'
import NavBarVue from '../components/NavBarVue.vue'
import SideBarVue from '../components/SideBarVue.vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { coinSupported } from '@/main'

// Use the store directly in the script setup block
const dashboardStore = useDashboardStore()
</script>

<style scoped>
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.row-1{
  height: 70px;
}
</style>
