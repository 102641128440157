<template>
  <div clas="container">
    <!-- Show the loader while the app is initializing -->
    <div :key="dashboardStore.compKey" v-if="isLoading == true">

      <LoadingVue />
    </div>
    <!-- Your main app content goes here -->
    <div v-if="isLoading == false && auth && balance">
      <router-view />


    </div>



  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useDashboardStore } from './store/dashboardStore'; // Store for managing global state
import LoadingVue from './components/LoadingVue.vue'; // Your loading component
import { auth, coinSupported } from './main';
import { balance } from './main';


// Access the store to track loading state
const dashboardStore = useDashboardStore();

// Compute the loading state
const isLoading = computed(() => { return dashboardStore.loading });

const coinSupported_ = computed(() => {
  return coinSupported
})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }


  }
}
</style>
