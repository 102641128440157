<template>
    <header class="pt-4 bg-screen">
        <div v-if="authStore.isAuth == false" class="flex justify-end w-full pr-4">
            <button @click="setModal(true, 'search-modal')"
                class="mr-2 py-1 rounded-full w-12 bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2">
                <img :src="require('../assets/icon-search.png')" alt="Icon" width="30" height="24" class="ml-1" />
                <!-- Adjust size as needed -->
            </button>
            <!-- <button v-if="dashboardStore.showChat == false" @click="setChatShow(true)"
                class="mr-2 py-1 flex justify-center items-center rounded-full w-12 bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2">
                <img :src="require('../assets/chat-2.png')" alt="Icon" width="20" height="20" class=" filter-white " />
            </button> -->
            <div style="height: 38px; border-radius: 12px"
                class="w-44 font-roboto font-extrabold text-center flex justify-between bg-profile-deposit border-profile-deposit">
                <button @click="setModal(true, 'login-modal')" class="text-white flex items-center pl-[1rem] text-xs">
                    Login
                </button>
                <button style="border-radius: 12px"
                    class="w-24 bg-gradient-to-b text-white text-xs from-button-yellow-1 to-button-yellow-2"
                    @click="setModal(true, 'register-modal')">
                    Register
                </button>
                <!--To remove-->
            </div>
        </div>

        <div v-if="authStore.isAuth == true" class="grid grid-cols-6 gap-2 font-righteous text-xs">
            <div class="pl-8 col-start-1 col-end-3">
                <div class="flex justify-start h-9">
                    <button class="side-button md:min-w-40 relative rounded-full vip">
                        <div class="flex">
                            <img style="bottom: 37px; left: -1px" class="absolute" src="../assets/gemos.png" />
                            <img style="bottom: -23px; left: -5px" class="absolute left-0" src="../assets/gemsos.png" />
                            <div class="z-0 flex w-full pl-5 items-center justify-center">
                                <span class="text-vip-button pr-1">Vip</span>
                                <span class="text-white">Club</span>
                            </div>
                        </div>
                    </button>
                    <button class="side-button md:min-w-48 ml-3 rounded-full relative bonus">
                        <div class="flex">
                            <img style="bottom: -17px; left: -8px" class="absolute" src="../assets/presentino.png" />
                            <div class="z-0 flex w-full pl-5 items-center justify-center">
                                <span
                                    class="bg-gradient-to-b from-deposit-button-1 to-deposit-button-2 pr-1 bg-clip-text text-transparent">Deposit</span><span
                                    class="text-white">Bonus</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            <div class="col-end-7 col-span-2 relative">
                <!-- <img src="../assets/elipssun.png" class="absolute bottom-0 top-0" /> -->

                <div class="flex justify-end pr-4 items-center">
                    <!--test code -->

                    <button @click="setModal(true, 'search-modal')"
                        class="mr-2 py-1 rounded-full w-12 bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2">
                        <img :src="require('../assets/icon-search.png')" alt="Icon" width="30" height="24"
                            class="ml-1" />
                        <!-- Adjust size as needed -->
                    </button>

                    <button @click="setModal(true, 'profile-modal')" class="z-0">
                        <img style="width: 60px" class="pr-3" src="../assets/avatar.png" />
                    </button>
                    <div :key="renderKey" style="height: 38px; border-radius: 12px"
                        class="w-56 mr-2 font-roboto font-extrabold text-center flex justify-between bg-profile-deposit border-profile-deposit">
                        <button class="w-full" @click="toggleDropdown">
                            <span class="pl-2 text-white flex items-center pr-3">
                                {{
                                '$'
                                + (
                                dashboardStore.multipliers.length > 0 && dashboardStore.selectedCoin
                                ? (
                                Number(
                                (
                                dashboardStore.multipliers.find(obj => obj[dashboardStore.selectedCoin] !== undefined)
                                || {}
                                )[dashboardStore.selectedCoin] || 0
                                ) *
                                Number(
                                dashboardStore.selectedCoin.toLowerCase() in balance_selected &&
                                isAllLowerCaseAlpha(dashboardStore.selectedCoin.toLowerCase())
                                ? getNestedPropertyCaseInsensitive(
                                balance_selected,
                                dashboardStore.selectedCoin.toLowerCase(),
                                'amount'
                                ) || '0.00'
                                : '0.00'
                                )
                                ).toFixed(2)
                                : '0.00'
                                )
                                }} </span>
                        </button>

                        <ul v-if="isOpen == true && dashboardStore.multipliers.length != 0"
                            class="dropdown-menu text-xs">
                            <li :class="index == 0 ? 'dropdown-item rounded-t-lg' : index == coinBalances.length - 1 ? 'dropdown-item rounded-b-lg' : 'dropdown-item'"
                                v-for="(coin, index) in coinBalances" :key="index" @click="selectCoin(coin, index)">

                                <img :src="coin.img" :alt="coin.name" width="20" height="20" />
                                {{ '$' +
                                (dashboardStore.multipliers.length &&
                                dashboardStore.multipliers.find(obj => obj[coin.symbol])?.[coin.symbol] * coin.amount ||
                                0).toFixed(2)
                                }}

                            </li>
                        </ul>
                        <button style="border-radius: 12px"
                            class="w-[7rem] z-0 bg-gradient-to-b text-white from-button-yellow-1 to-button-yellow-2 px-3"
                            @click="setModal(true, 'deposit-modal')">
                            DEPOSIT
                        </button>
                        <!--To remove-->
                    </div>
                    <!-- <div class="pr-2">
                        <button class="rounded-md w-9 h-9 border-2 border-border-dropdown-1 bg-dropdown-1">
                            <div class="flex justify-center">
                                <img src="../assets/bell.png" />
                            </div>
                        </button>
                    </div> -->
                    <div>
                        <button @click="handleLogout"
                            class="rounded-md w-9 h-9 border-2 border-border-dropdown-1 bg-dropdown-1">
                            <div class="flex justify-center">
                                <img src="../assets/log-out.png" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <ModalData :isOpen="modalState.modalOpen" :name="modalState.modalName"
        :header="modalState.modalName == 'forget-modal' ? 'Login' : header" />
</template>

<script setup>
import ModalData from '../components/ModalData'
import { useDashboardStore } from '@/store/dashboardStore'
import { useAuthStore } from '@/store/authStore'
import { doc, getDoc } from "firebase/firestore";
import { computed, ref, onMounted, watch, onBeforeUnmount, onUnmounted, inject } from 'vue'
import { getAuth, signOut } from "firebase/auth";
import { auth, coinSupported, db, logout, qtSelectedCurrency, logOut } from '@/main';


const balanceState = inject('balanceState');
const props = defineProps(['coinSupported', 'balance'])
const selectedCurrency = ref(qtSelectedCurrency ? qtSelectedCurrency : 'eth')
const isOpen = ref(false);
const isRotated = ref(false)
const balance = ref()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const header = ref('')
const selected_index = ref(0)
const renderKey = ref(0);
const supportedCoins = ref([])
const multipliers_ = ref([])
const coinSupported_ = computed(() => {
    return coinSupported
})
const balance_selected = ref({ eth: { amount: '0.00' } })


async function fetchUserBalance() {
    //test
    const playerUid = auth.currentUser ? auth.currentUser.uid : null
    if (playerUid) {
        const userRef = doc(db, `users/${playerUid}`)
        try {
            const userData = await getDoc(userRef)
            if (userData.exists()) {
                console.log('before init ')
                try {
                    balance.value = await userData.data().balances
                    console.log('after init1', balance.value)
                    console.log('after init2', balance.value)
                    console.log("render", renderKey.value)

                    balance_selected.value = balance.value
                    renderKey.value++
                    console.log("render", renderKey.value)

                    dashboardStore.setBalanceState(balance.value)

                    console.log("BALANCE", balance_selected.value)
                    console.log('Real balance', balance_selected.value, qtSelectedCurrency)
                } catch {
                    console.log('User document does not exist')
                }
            } else {
                console.log('User document does not exist')
            }
        } catch (error) {
            console.error('Error fetching user document:', error)
        }
    }
}

onMounted(async () => {
    console.log("LOGG", sessionStorage.getItem('login'))
    if (sessionStorage.getItem('login') == 'true' || sessionStorage.getItem('login') == true) {
        authStore.login()
        console.log("LOGGED")
    }
    window.addEventListener("resize", updateHeight);
    const baseDataRef = doc(db, 'aBase/baseInfo')
    const baseData = await getDoc(baseDataRef)

    supportedCoins.value = (await baseData.data()?.coinSupported) || {}
    const coins = (await baseData.data()?.coinSupported) || {} // Assign the fetched value to coinSupported
    console.log("UID", auth, props.coinSupported, coins)

    await dashboardStore.getEthMultiplier(coins)
    multipliers_.value = dashboardStore.multipliers

    console.log("ETH", dashboardStore.multipliers)
    const playerUid = auth ? auth?.currentUser?.uid : null// Get the UID of the authenticated user
    console.log("QTselect", playerUid)
    const userRef = doc(db, `users/${playerUid}`) // 'db' should be your Firestore instance

    fetchUserBalance()
    console.log("COIN", props.coinSupported, coinSupported)
    dashboardStore.setCoinSupported(supportedCoins.value)

    console.log("BALANCE", qtSelectedCurrency)
    const userData = await getDoc(userRef)
    const qtSelect = await userData.data()?.qtSelectedCurrency
    console.log("QTselect", qtSelect)
    selectedCurrency.value = qtSelect
    dashboardStore.selectGameCurrency(qtSelect)
    compKey.value = false



})

const coin_selected = computed(() => {
    return dashboardStore.selectedCoin
}

)

const compKey = ref(false)
async function exitGame() {
    dashboardStore.setCheckToggle(true)
    dashboardStore.setLoading(true)


    dashboardStore.setShowIframe(false)
    dashboardStore.setGameUrl('')
    await fetchUserBalance()
    await dashboardStore.getEthMultiplier(props.coinSupported ? props.coinSupported : coinSupported)
    dashboardStore.setCheckToggle(false)
    dashboardStore.setLoading(false)
    compKey.value = true


}
const selectedOption = ref(coin_selected.value ? coin_selected.value : 'ETH');

const selectCoin = (coin, index, value) => {
    selected_index.value = index
    console.log(coin)
    selectedOption.value = coin.symbol;
    dashboardStore.setSelectedCoin(coin.symbol)
    dashboardStore.selectGameCurrency(coin.symbol)

    isOpen.value = false;
};
const componentRef = ref(null);


const handleClickOutside = (event) => {
    console.log(event.target, event.currentTarget, "target")
    if (event.target === 'ol') {
        console.log("EVENT", 'Directly clicked on the <ol>');
    } else {
        console.log("EVENT", 'Clicked on a child element of <ol>');
    }
    if (componentRef.value && !componentRef.value.contains(event.target)) {
        // Perform your action here
        isOpen.value = false
        isRotated.value = false
        console.log("Clicked outside the component");
    }
};
onMounted(() => {

    document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {

    document.removeEventListener("click", handleClickOutside);
});
const coinBalances = computed(() => {
    console.log("COINSUPPORTED")
    let coinSupported2
    if (Object.keys(props.coinSupported).length != 0 || props.coinSupported.length != 0) {
        console.log("1", props.coinSupported)
        coinSupported2 = props.coinSupported
    }
    if (Object.keys(coinSupported_.value).length != 0 || coinSupported_.value.length != 0) {
        console.log("2", coinSupported_.value)

        coinSupported2 = coinSupported_.value
    }
    if (Object.keys(coinSupported).length != 0 || coinSupported.length != 0) {
        console.log("3", coinSupported)
        coinSupported2 = coinSupported
    }
    console.log("CoinSupported", dashboardStore.coinSupported)

    if (dashboardStore.coinSupported.length != 0) {

        return dashboardStore.coinSupported.length != 0 && dashboardStore.coinSupported.filter((coin) => coin.symbol.toLowerCase() !== "ghub") // Exclude GHUB
            .map((coin) => {
                const balance_ = balance_selected.value ? balance_selected.value[coin.symbol.toLowerCase()]?.amount ?? 0.00 : 0.00;
                console.log("balance", coin.img)
                return {
                    symbol: coin.symbol,
                    name: coin.name,
                    img: coin.img,
                    amount: balance_,
                    multiplier: dashboardStore.multipliers[coin.symbol]
                };
            });
    } else return null

});
const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
    isRotated.value = !isRotated.value;
    console.log("BALANCE", balance_selected.value)
    console.log("open", isOpen.value)
};
const filteredCoinBalances = computed(() => {
    console.log("COINBALANCES", coinBalances.value)
    return coinBalances.value && coinBalances.value.filter(coin =>
        coin.symbol.includes(dashboardStore.selectedCoin)
    );
});

const amount = computed(() => {
    return balance.value && balance.value[dashboardStore.selectedCoin.toLocaleLowerCase()].amount;
})

const handleHomeClick = () => {
    window.parent.postMessage("backHome", "*");
}

function getNestedPropertyCaseInsensitive(obj, property, nestedKey) {
    // Normalize the outer property case
    const normalizedKey = Object.keys(obj).find(
        key => key.toLowerCase() === property.toLowerCase()
    );

    // Access the nested property if it exists
    return normalizedKey && obj[normalizedKey][nestedKey] !== undefined
        ? obj[normalizedKey][nestedKey]
        : undefined;
}


const multipliers = computed(() => {
    console.log("MULTSSS", dashboardStore.multipliers)
    return dashboardStore.multipliers
})
const setChatShow = val => {
    dashboardStore.setShowchat(val)

}
const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
    if (name == 'register-modal') dashboardStore.setHeader('Sign Up')
    else if (name == 'login-modal' || name == 'forget-modal') dashboardStore.setHeader('Login')
    else if (name == 'profile-modal') dashboardStore.setHeader('Profile')
    else if (name == 'deposit-modal') dashboardStore.setHeader('Deposit')
    else if (name == 'search-modal') dashboardStore.setHeader('Search')
}

const handleLogout = () => {
    console.log("here")
    logOut().then(() => {
        sessionStorage.clear()
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
        authStore.logout()
    })
}
const iframeHeight = ref(window.innerHeight - 3);
const updateHeight = () => {
    iframeHeight.value = window.innerHeight - 3;
};

const modalState = computed(() => dashboardStore.modal)

function isAllLowerCaseAlpha(str) {
    return /^[a-z]+$/.test(str); // Matches only lowercase alphabetic characters
}

// Watch for changes in the computed property
watch(
    multipliers,
    (newVal, oldVal) => {
        console.log('Multipliers changed:', { newVal, oldVal });
        // Perform any additional logic when the value changes
    }
);
</script>

<style scoped>
.vip {
    background: linear-gradient(to bottom, #182552, #1da2cc);
}

.icon {
    background: url('../assets/icon-search.png') no-repeat left;
    margin-left: 10px;
    padding-left: 33px;
}

.input-icon-2 input::placeholder {
    padding-left: 15px;
    color: #fff;
}

.input-container {
    position: relative;
    width: 230px;
}

.input-container input::placeholder {
    color: white;
}

.input-container input {
    width: 100%;
    padding: 8px 20px 8px 55px;
    /* Adjust padding to make room for icon and pipe */
    border-width: 2px;
    font-size: 16px;
    box-sizing: border-box;
    min-width: 170px;
}

.input-icon-2 {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.vip::before {
    content: '';
    background: linear-gradient(to bottom, #121b3e, #034a68);
}

.bonus {
    background: linear-gradient(to bottom, #2b2f4e, #7b5635);
}

.bonus::before {
    content: '';
    background: linear-gradient(to bottom, #121b3e, #403135);
}

.side-button::before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 30px;
}

.filter-white {
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);
}

.dropdown {
    position: relative;
    display: inline-block;
}

svg {
    transition: transform 0.2s ease-in-out;
}

svg.rotate-90 {
    transform: rotate(90deg);
}

.dropdown-menu {
    display: block;
    position: absolute;
    top: 49px;
    background-color: #f9f9f9;
    min-width: 100px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ;
}

.dropdown-item img {
    margin-right: 8px;
}

.dropdown-item:hover {
    background-color: #ddd;

}
</style>
