<template>
    <div class="max-w-[1200px] max-h-[600px] overflow-hidden flex flex-col">
        <div ref="scrollableContent" class="flex-grow overflow-y-auto relative" @scroll="checkScroll">
            <div class="grid grid-cols-7 gap-4 pb-10">
                <button v-for="(item, index) in items" :key="index" :disabled="!auth.currentUser?.uid"
                    @click="openframe(item)">
                    <img class="rounded-md" :src="item.images && item && item.images[2]
                        ? item.images[2].url + '&width=120&height=120'
                        : `https://client.qtlauncher.com/images/?id=${item.id}_en_US&type=logo-square&width=100&height=100`
                        " alt="" />
                </button>
            </div>
            <div class="py-2 w-full flex justify-center">

                <div v-if="items.length == 20" class="w-[200px] pb-4">
                    <ButtonBox :text="'Load More'" @click="loadMore" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import { computed, ref, onMounted } from 'vue'
import ButtonBox from '../form/ButtonBox.vue'
import axios from 'axios'
import { auth } from '@/main';

const props = defineProps(['header'])
const dashboardStore = useDashboardStore()
const scrollableContent = ref(null)
const iframeOpened = ref(false)
const lengths = 20
const times = ref(1)
const showLoadMore = ref(true)
const loadMoreTrigger = ref(null)
const item_length = ref(0)
// const items = computed(() => {
//     let resultArray = [...data]
//     if (dashboardStore.sideCat == 'Casino') {

//         if (props.header == 'Top Games') {
//             const ids = dashboardStore.popularGames.map(item => item.id)
//             return resultArray.filter(item => ids.includes(item.id)).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Bonus Buy') {
//             // Apply BONUS_BUY filtering
//             return resultArray.filter(
//                 item => item.features && item.features.some(feature => feature.id === 'BONUS_BUY')
//             ).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'New') {
//             return [...resultArray].sort(
//                 (a, b) =>
//                     new Date(a.release_date.slice(0, 16)) - new Date(b.release_date.slice(0, 16))
//             ).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Slots') {
//             return resultArray.filter(item => item.category.includes('SLOT')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Instant Win') {
//             return resultArray.filter(item => item.category.includes('INSTANTWIN')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Scratch') {
//             return resultArray.filter(item => item.category.includes('SCRATCHCARD')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Shooting') {
//             return resultArray.filter(item => item.category.includes('SHOOTING')).slice(0,(lengths *times.value))
//         }
//     }
//     if (dashboardStore.sideCat == 'Live Casino') {
//         const games = resultArray.filter(item => item.category.includes('LIVECASINO'))
//         if (props.header == 'Top Games') {
//             const ids = dashboardStore.popularGames.map(item => item.id)
//             return games.filter(item => ids.includes(item.id)).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Blackjack') {
//             return games.filter(item => item.category.includes('BLACKJACK')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Roulette') {
//             return games.filter(item => item.category.includes('ROULETTE')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Baccarat') {
//             return games.filter(item => item.category.includes('BACCARAT')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Game Shows') {
//             return games.filter(item => item.category.includes('GAMESHOWS')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Poker') {
//             return games.filter(item => item.category.includes('POKER')).slice(0,(lengths *times.value))
//         }
//     }
//     if (dashboardStore.sideCat == 'eSports') {
//         const games = resultArray.filter(item => item.category.includes('ESPORTS'))
//         if (props.header == 'FPS') {
//             return games.filter(item => item.category.includes('FPS')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'MOBA') {
//             return games.filter(item => item.category.includes('MOBA')).slice(0,(lengths *times.value))
//         }
//         if (props.header == 'Sports') {
//             return games.filter(item => item.category.includes('SPORTS')).slice(0,(lengths *times.value))
//         }


// }
//     return resultArray
// })

const openframe = item => {
    dashboardStore.getGameUrl(item.id)
    if (!dashboardStore.sideGameOpened) {
        dashboardStore.setSideGame(true)
    }
}

const loadMore = () => {
    times.value++
}

const getRealLink = link => {
    const queryString = link.split('?')[1]
    const param = new URLSearchParams(queryString)
    const result = param.get('cursor')
    return result
}

const checkScroll = () => {
    if (!scrollableContent.value || !loadMoreTrigger.value) {
        return
    }

    const triggerPosition = loadMoreTrigger.value.getBoundingClientRect().top
    const contentPosition = scrollableContent.value.getBoundingClientRect().bottom

    if (triggerPosition <= contentPosition) {
        showLoadMore.value = true
    } else {
        showLoadMore.value = false
    }
}
</script>

<style scoped>
/* Custom checkbox tick color */

.scrollable-content {
    position: relative;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
